@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .title {
    @apply font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#172231d9] from-40% to-[#c3d4d3d9];
  }
  .title-white {
    @apply font-bold text-transparent bg-clip-text bg-gradient-to-br from-white from-40% to-[#bebebec2];
  }
  .title-black {
    @apply font-bold text-transparent bg-clip-text bg-gradient-to-br from-main from-40% to-[#3c3c3c66];
  }
  .tile {
    @apply h-full flex flex-col items-start shadow-md hover:shadow-inner transition-all duration-500 ease-in-out rounded-2xl bg-creme bg-opacity-50 px-7 py-5;
  }
  .dropdown-symbol {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .glass {
    @apply backdrop-saturate-150 backdrop-blur-[15px] bg-white bg-opacity-65;
  }
}

h2 {
  @apply text-2xl font-bold;
}

h3 {
  @apply text-2xl font-semibold;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

blockquote {
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 3em;
  line-height: 0.1em;
  margin-right: 0.1em;
  vertical-align: -0.4em;
}
blockquote:after {
  color: #ccc;
  content: close-quote;
  font-size: 3em;
  line-height: 0.1em;
  margin-left: 0.1em;
  vertical-align: -0.425em;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

@keyframes moveSlideshow {
  0% {
      transform: translateX(0)
  }

  to {
      transform: translateX(-100%)
  }
}

.animate-slide-left {
  animation: moveSlideshow 30s linear infinite;
}